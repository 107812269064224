
export default {
  props: {
    type: {
      type: String,
      default: 'star',
      validator: (type) => ['star', 'heart', 'plane'].includes(type),
    },
    count: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      icon: {
        star: ['fas', 'star'],
        heart: ['fas', 'heart'],
        plane: ['fas', 'plane-up']
      }
    }
  }
};
