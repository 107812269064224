
export default {
  props: {
    image: {
      type: String,
      default: null,
    },
    star: {
      type: Number,
      default: 0,
    },
    mileage: {
      type: Number,
      default: 0,
    },
    heart: {
      type: Number,
      default: 0,
    },
  },
};
